<template>
  <v-row
    no-gutters
    class="h-100"
  >
    <v-col cols="12" offset="0" md="4" offset-md="4" class="d-flex flex-column justify-center align-center text-center">
      <v-img
        :aspect-ratio="16/9"
        width="50%"
        contain
        :src="require('@/assets/images/errors/404.svg')"
      ></v-img>
      <h1 class="text-h5 mb-8">{{ $t('errorPage.title429') }}</h1>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Error429'
}
</script>
